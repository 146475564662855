<template>
  <div id="container">
    <Navbar @lang="getLang" color="white" />
    <div id="content-wrapper">
        <CampaignCase type="Gamification" timeframe="2 Months, on-going" banner="home-credit/Group 381-min-min.png" logo="home-credit/home-credit-logo.png"/>
    </div>
    <DetailCampaign contentdetail="home-credit" :text="lang === 'id' ? textDetail : textDetailEn"/>
    <CampaignDemo :text="lang === 'id' ? textDemo : textDemoEn" :demo="demo"/>
    <CampaignEx :lang="lang" :ex="campaignEx"/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import CampaignCase from '@/components/CaseStudy/CampaignCase'
import DetailCampaign from '@/components/CaseStudy/DetailCampaign'
import CampaignDemo from '@/components/CaseStudy/CampaignDemo'
import CampaignEx from '@/components/CaseStudy/CampaignEx'
export default {
  components: {
    Navbar,
    Footer,
    CampaignCase,
    DetailCampaign,
    CampaignDemo,
    CampaignEx
  },
  data () {
    return {
      lang: '',
      textDetail: 'Strategi yang dilakukan untuk meningkatkan awareness terhadap produk Home Credit, HIGO membuat permainan dengan judul Memorable Game. Game ini berisi beberapa produk pilihan yang bisa dicicil dengan syarat dan ketentuan yang mudah',
      textDetailEn: "To increase user's awareness of Home Credit products, HIGO created simple Memory Game. The assets used in this game is all products that can be paid using Home Credit Installment. With this gamification approach, user awareness is much higher than simple banner",
      textDemoEn: 'Through Memorable Game, the successful conversion of visits to the Home Credit website has reached 90%. This means that Transjakarta users have an interest in seeing more detailed information on electronic products used as games',
      textDemo: 'Lewat Memorable Game, konversi keberhasilan kunjungan ke website Home Credit mencapai 90%. Itu artinya pengguna Transjakarta memiliki ketertarikan untuk melihat lebih detail informasi produk elektronik yang dijadikan game',
      demo: {
        first: {
          image: 'home-credit/mc2-min-min.png',
          text: 'Banner'
        },
        second: {
          image: 'home-credit/mc3-min-min.png',
          text: 'Memorable Game'
        },
        third: {
          image: 'home-credit/mc4-min-min.png',
          text: 'Website'
        }
      },
      campaignEx: {
        text: 'Home Credit melakukan Block Location untuk menayangkan iklan mereka. Mulai dari area publik (Transjakarta) dan beberapa mall yang memiliki outlet / POS Home Credit',
        textEn: 'Home Credit does Block Location to serve their ads. Starting from the public area (Transjakarta) and Home Credit outlets / POS',
        image: 'home-credit/Mask Group 12-min-min.png'
      }
    }
  },
  methods: {
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow-x: hidden;
}

#content-wrapper {
  padding-top: 3rem;
  position: relative;
  height: auto;
  /* min-height: calc(100vh - 3rem);
  max-height: 100vh; */
  width: 100%;
}
</style>
